@font-face {
  font-display: block;
  font-family: "Bridge Head Ext";
  font-style: normal;
  font-weight: 600;
  src: url("../webfonts/Bridge-Head-Ext-Bold.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Bridge Head Text";
  font-style: normal;
  font-weight: 500;
  src: url("../webfonts/Bridge-Text-Medium.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Akkurat LL";
  font-style: normal;
  font-weight: 700;
  src: url("../webfonts/AkkuratLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Akkurat LL";
  font-style: italic;
  font-weight: 400;
  src: url("../webfonts/AkkuratLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Akkurat LL";
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/AkkuratLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "Akkurat Mono LL";
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/AkkuratMonoLLWeb-Regular.woff2") format("woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.article-content > p:first-child {
  @apply text-xs sm:text-sm font-bold not-italic leading-[1.4] tracking-tight mb-12 sm:mb-[60px];
}

.article-content > p:first-child > em,
.article-content > p:first-child i {
  @apply not-italic;
}

.article-content h2 + figure,
.article-content h3 + figure,
.article-content h4 + figure,
.article-content h5 + figure,
.article-content h6 + figure {
  @apply mt-8 sm:mt-10;
}

input[type="search"]::-webkit-search-cancel-button {
  @apply appearance-none cursor-pointer h-[24px] w-[24px] bg-close bg-cover;
}

details > summary {
  @apply list-none;
}

details > summary::marker,
details summary::-webkit-details-marker {
  @apply hidden;
}

* {
  @apply scroll-smooth;
}
