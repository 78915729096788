@font-face {
  font-display: block;
  font-family: Bridge Head Ext;
  font-style: normal;
  font-weight: 600;
  src: url("Bridge-Head-Ext-Bold.401874e8.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: Bridge Head Text;
  font-style: normal;
  font-weight: 500;
  src: url("Bridge-Text-Medium.c369eee5.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: Akkurat LL;
  font-style: normal;
  font-weight: 700;
  src: url("AkkuratLLWeb-Bold.b97726ee.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: Akkurat LL;
  font-style: italic;
  font-weight: 400;
  src: url("AkkuratLLWeb-Italic.9e5bf72a.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: Akkurat LL;
  font-style: normal;
  font-weight: 400;
  src: url("AkkuratLLWeb-Regular.eae48bb4.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: Akkurat Mono LL;
  font-style: normal;
  font-weight: 400;
  src: url("AkkuratMonoLLWeb-Regular.5129acc8.woff2") format("woff2");
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.invisible {
  visibility: hidden;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-\[-42px\] {
  bottom: -42px;
}

.bottom-\[-50px\] {
  bottom: -50px;
}

.bottom-\[45px\] {
  bottom: 45px;
}

.right-\[-7px\] {
  right: -7px;
}

.top-0 {
  top: 0;
}

.z-0 {
  z-index: 0;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[31px\] {
  margin-bottom: 31px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.mb-\[70px\] {
  margin-bottom: 70px;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[-16px\] {
  margin-left: -16px;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[6px\] {
  margin-left: 6px;
}

.ml-\[8px\] {
  margin-left: 8px;
}

.ml-auto {
  margin-left: auto;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-\[-16px\] {
  margin-right: -16px;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[-60px\] {
  margin-top: -60px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-\[234px\] {
  height: 234px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[273px\] {
  height: 273px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[70vh\] {
  height: 70vh;
}

.h-full {
  height: 100%;
}

.min-h-\[28px\] {
  min-height: 28px;
}

.w-\[146px\] {
  width: 146px;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[269px\] {
  width: 269px;
}

.w-\[28px\] {
  width: 28px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[306px\] {
  width: 306px;
}

.w-full {
  width: 100%;
}

.min-w-\[28px\] {
  min-width: 28px;
}

.max-w-\[114px\] {
  max-width: 114px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-\[187px\] {
  flex-basis: 187px;
}

.border-collapse {
  border-collapse: collapse;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[100px\] {
  border-radius: 100px;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-bl-\[4px\] {
  border-bottom-left-radius: 4px;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl-\[4px\] {
  border-top-left-radius: 4px;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l-\[4px\] {
  border-left-width: 4px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#C8C8C8\] {
  --tw-border-opacity: 1;
  border-color: rgb(200 200 200 / var(--tw-border-opacity));
}

.border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.border-\[rgba\(255\,255\,255\,0\.4\)\] {
  border-color: #fff6;
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 121 233 / var(--tw-border-opacity));
}

.border-contrasting-purple {
  --tw-border-opacity: 1;
  border-color: rgb(105 11 150 / var(--tw-border-opacity));
}

.border-plum {
  --tw-border-opacity: 1;
  border-color: rgb(63 8 83 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-l-\[\#c8c8c8\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(200 200 200 / var(--tw-border-opacity));
}

.border-l-green {
  --tw-border-opacity: 1;
  border-left-color: rgb(109 234 143 / var(--tw-border-opacity));
}

.border-l-orange {
  --tw-border-opacity: 1;
  border-left-color: rgb(245 117 73 / var(--tw-border-opacity));
}

.bg-\[\#4FB06A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(79 176 106 / var(--tw-bg-opacity));
}

.bg-\[\#EBDAFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 218 255 / var(--tw-bg-opacity));
}

.bg-beige {
  --tw-bg-opacity: 1;
  background-color: rgb(246 242 234 / var(--tw-bg-opacity));
}

.bg-contrasting-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(105 11 150 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(109 234 143 / var(--tw-bg-opacity));
}

.bg-plum {
  --tw-bg-opacity: 1;
  background-color: rgb(63 8 83 / var(--tw-bg-opacity));
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(215 184 255 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-line-graph {
  background-image: url("line-graph.4b32379a.svg");
}

.bg-square-1 {
  background-image: url("square-1.14739bb5.svg");
}

.bg-square-2 {
  background-image: url("square-2.61ea65ab.svg");
}

.bg-square-3 {
  background-image: url("square-3.93de4fd5.svg");
}

.bg-\[left_10\%\] {
  background-position: 0 10%;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.stroke-\[\#3F0853\] {
  stroke: #3f0853;
}

.stroke-black {
  stroke: #000;
}

.stroke-contrasting-purple {
  stroke: #690b96;
}

.stroke-green {
  stroke: #6dea8f;
}

.stroke-plum {
  stroke: #3f0853;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[3px\] {
  padding: 3px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[60px\] {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[149px\] {
  padding-bottom: 149px;
}

.pb-\[60px\] {
  padding-bottom: 60px;
}

.pb-\[75px\] {
  padding-bottom: 75px;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-\[24px\] {
  padding-left: 24px;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-akkurat {
  font-family: Akkurat LL, sans-serif;
}

.font-akkurat-mono {
  font-family: Akkurat Mono LL, sans-serif;
}

.font-bridge-head-ext {
  font-family: Bridge Head Ext, sans-serif;
}

.font-bridge-head-text {
  font-family: Bridge Head Text, sans-serif;
}

.text-2xl {
  font-size: 48px;
}

.text-2xs {
  font-size: 18px;
}

.text-3xs {
  font-size: 16px;
}

.text-4xs {
  font-size: 14px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-lg {
  font-size: 32px;
}

.text-md {
  font-size: 22px;
}

.text-ml {
  font-size: 28px;
}

.text-sm {
  font-size: 24px;
}

.text-xl {
  font-size: 40px;
}

.text-xs {
  font-size: 20px;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.not-italic {
  font-style: normal;
}

.leading-\[0\] {
  line-height: 0;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.leading-\[1\.4\] {
  line-height: 1.4;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#3F0853\] {
  --tw-text-opacity: 1;
  color: rgb(63 8 83 / var(--tw-text-opacity));
}

.text-\[\#3F3F3F\] {
  --tw-text-opacity: 1;
  color: rgb(63 63 63 / var(--tw-text-opacity));
}

.text-\[\#757575\] {
  --tw-text-opacity: 1;
  color: rgb(117 117 117 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-contrasting-purple {
  --tw-text-opacity: 1;
  color: rgb(105 11 150 / var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(109 234 143 / var(--tw-text-opacity));
}

.text-light-gray {
  --tw-text-opacity: 1;
  color: rgb(183 183 183 / var(--tw-text-opacity));
}

.text-plum {
  --tw-text-opacity: 1;
  color: rgb(63 8 83 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[counter-increment\:section\] {
  counter-increment: section;
}

.article-content > p:first-child {
  letter-spacing: -.025em;
  margin-bottom: 3rem;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 640px) {
  .article-content > p:first-child {
    margin-bottom: 60px;
    font-size: 24px;
  }
}

.article-content > p:first-child > em, .article-content > p:first-child i {
  font-style: normal;
}

.article-content h2 + figure, .article-content h3 + figure, .article-content h4 + figure, .article-content h5 + figure, .article-content h6 + figure {
  margin-top: 2rem;
}

@media (min-width: 640px) {
  .article-content h2 + figure, .article-content h3 + figure, .article-content h4 + figure, .article-content h5 + figure, .article-content h6 + figure {
    margin-top: 2.5rem;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("close.2ba2cd10.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
}

details > summary {
  list-style-type: none;
}

details > summary::marker {
  display: none;
}

details summary::-webkit-details-marker {
  display: none;
}

* {
  scroll-behavior: smooth;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-\[-114px\]:before {
  content: var(--tw-content);
  left: -114px;
}

.before\:top-\[-89px\]:before {
  content: var(--tw-content);
  top: -89px;
}

.before\:top-\[30px\]:before {
  content: var(--tw-content);
  top: 30px;
}

.before\:z-\[1\]:before {
  content: var(--tw-content);
  z-index: 1;
}

.before\:mb-5:before {
  content: var(--tw-content);
  margin-bottom: 1.25rem;
}

.before\:mr-\[16px\]:before {
  content: var(--tw-content);
  margin-right: 16px;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-\[180px\]:before {
  content: var(--tw-content);
  height: 180px;
}

.before\:h-\[6px\]:before {
  content: var(--tw-content);
  height: 6px;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:w-1:before {
  content: var(--tw-content);
  width: .25rem;
}

.before\:w-\[187px\]:before {
  content: var(--tw-content);
  width: 187px;
}

.before\:w-\[8px\]:before {
  content: var(--tw-content);
  width: 8px;
}

.before\:min-w-\[8px\]:before {
  content: var(--tw-content);
  min-width: 8px;
}

.before\:rounded-bl-lg:before {
  content: var(--tw-content);
  border-bottom-left-radius: .5rem;
}

.before\:rounded-tl-lg:before {
  content: var(--tw-content);
  border-top-left-radius: .5rem;
}

.before\:border-\[1px\]:before {
  content: var(--tw-content);
  border-width: 1px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-blue:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 121 233 / var(--tw-border-opacity));
}

.before\:bg-blue:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 121 233 / var(--tw-bg-opacity));
}

.before\:bg-green:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(109 234 143 / var(--tw-bg-opacity));
}

.before\:bg-purple:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(215 184 255 / var(--tw-bg-opacity));
}

.before\:bg-splodge:before {
  content: var(--tw-content);
  background-image: url("splodge.7a194b2a.svg");
}

.before\:bg-splodge-1:before {
  content: var(--tw-content);
  background-image: url("splodge-1.b8059738.svg");
}

.before\:bg-no-repeat:before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'–\'\]:before {
  --tw-content: "–";
  content: var(--tw-content);
}

.before\:content-\[counters\(section\,\'\.\'\)\]:before {
  --tw-content: counters(section, ".");
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:bottom-\[-6px\]:after {
  content: var(--tw-content);
  bottom: -6px;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:right-\[-2\.25rem\]:after {
  content: var(--tw-content);
  right: -2.25rem;
}

.after\:right-\[11px\]:after {
  content: var(--tw-content);
  right: 11px;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-\[50\%\]:after {
  content: var(--tw-content);
  top: 50%;
}

.after\:mr-3:after {
  content: var(--tw-content);
  margin-right: .75rem;
}

.after\:hidden:after {
  content: var(--tw-content);
  display: none;
}

.after\:h-\[16px\]:after {
  content: var(--tw-content);
  height: 16px;
}

.after\:h-\[233px\]:after {
  content: var(--tw-content);
  height: 233px;
}

.after\:h-\[24px\]:after {
  content: var(--tw-content);
  height: 24px;
}

.after\:h-\[735px\]:after {
  content: var(--tw-content);
  height: 735px;
}

.after\:w-\[16px\]:after {
  content: var(--tw-content);
  width: 16px;
}

.after\:w-\[24px\]:after {
  content: var(--tw-content);
  width: 24px;
}

.after\:w-\[335px\]:after {
  content: var(--tw-content);
  width: 335px;
}

.after\:w-\[377px\]:after {
  content: var(--tw-content);
  width: 377px;
}

.after\:translate-y-\[-50\%\]:after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-45:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bg-plum:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(63 8 83 / var(--tw-bg-opacity));
}

.after\:bg-chart:after {
  content: var(--tw-content);
  background-image: url("chart.1aa7b82f.svg");
}

.after\:bg-document-cards:after {
  content: var(--tw-content);
  background-image: url("document-cards.77d9134e.svg");
}

.after\:bg-down-arrow:after {
  content: var(--tw-content);
  background-image: url("down-arrow.865a7951.svg");
}

.after\:bg-contain:after {
  content: var(--tw-content);
  background-size: contain;
}

.after\:bg-no-repeat:after {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:border-t-\[1px\]:first-child {
  border-top-width: 1px;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.last\:pb-20:last-child {
  padding-bottom: 5rem;
}

.hover\:border-b-\[1\.5px\]:hover {
  border-bottom-width: 1.5px;
}

.hover\:border-solid:hover {
  border-style: solid;
}

.hover\:border-\[\#C8C8C8\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(200 200 200 / var(--tw-border-opacity));
}

.hover\:border-contrasting-purple:hover {
  --tw-border-opacity: 1;
  border-color: rgb(105 11 150 / var(--tw-border-opacity));
}

.hover\:border-purple:hover {
  --tw-border-opacity: 1;
  border-color: rgb(215 184 255 / var(--tw-border-opacity));
}

.hover\:bg-\[\#55E17B\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(85 225 123 / var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-contrasting-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(105 11 150 / var(--tw-bg-opacity));
}

.hover\:bg-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(215 184 255 / var(--tw-bg-opacity));
}

.hover\:text-contrasting-purple:hover {
  --tw-text-opacity: 1;
  color: rgb(105 11 150 / var(--tw-text-opacity));
}

.hover\:text-purple:hover {
  --tw-text-opacity: 1;
  color: rgb(215 184 255 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:border-plum:focus {
  --tw-border-opacity: 1;
  border-color: rgb(63 8 83 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.active\:bg-plum:active {
  --tw-bg-opacity: 1;
  background-color: rgb(63 8 83 / var(--tw-bg-opacity));
}

.group[open] .group-open\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:ml-3 {
  margin-left: .75rem;
}

.group:hover .group-hover\:translate-x-\[2px\] {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:stroke-contrasting-purple {
  stroke: #690b96;
}

.group:hover .group-hover\:stroke-white {
  stroke: #fff;
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-\[1\] {
  opacity: 1;
}

.group:hover .group-hover\:transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.group:active .group-active\:stroke-white {
  stroke: #fff;
}

@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:-order-none {
    order: 0;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mb-\[120px\] {
    margin-bottom: 120px;
  }

  .sm\:mb-\[140px\] {
    margin-bottom: 140px;
  }

  .sm\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .sm\:mb-\[84px\] {
    margin-bottom: 84px;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-\[-2\.25rem\] {
    margin-left: -2.25rem;
  }

  .sm\:ml-\[-76px\] {
    margin-left: -76px;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mr-\[-2\.25rem\] {
    margin-right: -2.25rem;
  }

  .sm\:mr-\[-76px\] {
    margin-right: -76px;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-11 {
    margin-top: 2.75rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-14 {
    margin-top: 3.5rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-9 {
    margin-top: 2.25rem;
  }

  .sm\:mt-\[-200px\] {
    margin-top: -200px;
  }

  .sm\:mt-\[60px\] {
    margin-top: 60px;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-\[62px\] {
    height: 62px;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:min-h-\[calc\(100vh-347px\)\] {
    min-height: calc(100vh - 347px);
  }

  .sm\:w-\[312px\] {
    width: 312px;
  }

  .sm\:w-\[70\%\] {
    width: 70%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .sm\:gap-x-\[4\.5rem\] {
    column-gap: 4.5rem;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:border-b-\[1px\] {
    border-bottom-width: 1px;
  }

  .sm\:border-l-\[1px\] {
    border-left-width: 1px;
  }

  .sm\:border-solid {
    border-style: solid;
  }

  .sm\:border-\[\#ddd\] {
    --tw-border-opacity: 1;
    border-color: rgb(221 221 221 / var(--tw-border-opacity));
  }

  .sm\:border-l-\[rgba\(255\,255\,255\,0\.4\)\] {
    border-left-color: #fff6;
  }

  .sm\:stroke-white {
    stroke: #fff;
  }

  .sm\:p-\[60px\] {
    padding: 60px;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-\[118px\] {
    padding-left: 118px;
    padding-right: 118px;
  }

  .sm\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pb-\[50px\] {
    padding-bottom: 50px;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pl-\[28px\] {
    padding-left: 28px;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-2xs {
    font-size: 18px;
  }

  .sm\:text-3xl {
    font-size: 56px;
  }

  .sm\:text-3xs {
    font-size: 16px;
  }

  .sm\:text-lg {
    font-size: 32px;
  }

  .sm\:text-md {
    font-size: 22px;
  }

  .sm\:text-ml {
    font-size: 28px;
  }

  .sm\:text-sm {
    font-size: 24px;
  }

  .sm\:text-xl {
    font-size: 40px;
  }

  .sm\:text-xs {
    font-size: 20px;
  }

  .sm\:leading-\[1\.1\] {
    line-height: 1.1;
  }

  .sm\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .sm\:leading-\[1\.3\] {
    line-height: 1.3;
  }

  .sm\:leading-\[1\.5\], .sm\:leading-normal {
    line-height: 1.5;
  }

  .sm\:tracking-normal {
    letter-spacing: 0;
  }

  .sm\:after\:absolute:after {
    content: var(--tw-content);
    position: absolute;
  }

  .sm\:after\:bottom-\[-4px\]:after {
    content: var(--tw-content);
    bottom: -4px;
  }

  .sm\:after\:left-0:after {
    content: var(--tw-content);
    left: 0;
  }

  .sm\:after\:h-\[1px\]:after {
    content: var(--tw-content);
    height: 1px;
  }

  .sm\:after\:w-0:after {
    content: var(--tw-content);
    width: 0;
  }

  .sm\:after\:w-full:after {
    content: var(--tw-content);
    width: 100%;
  }

  .sm\:after\:bg-green:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(109 234 143 / var(--tw-bg-opacity));
  }

  .sm\:after\:transition-all:after {
    content: var(--tw-content);
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:after\:content-\[\'\'\]:after {
    --tw-content: "";
    content: var(--tw-content);
  }

  .last\:sm\:mb-6:last-child {
    margin-bottom: 1.5rem;
  }

  .last\:sm\:mb-8:last-child {
    margin-bottom: 2rem;
  }

  .last\:sm\:pb-6:last-child {
    padding-bottom: 1.5rem;
  }

  .last\:sm\:pb-8:last-child {
    padding-bottom: 2rem;
  }

  .sm\:last\:pb-0:last-child {
    padding-bottom: 0;
  }

  .sm\:last\:pb-8:last-child {
    padding-bottom: 2rem;
  }

  .hover\:sm\:after\:w-full:hover:after {
    content: var(--tw-content);
    width: 100%;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:bottom-\[-0\.4vw\] {
    bottom: -.4vw;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:top-\[50\%\] {
    top: 50%;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .md\:mb-\[78px\] {
    margin-bottom: 78px;
  }

  .md\:ml-\[-2\.25rem\] {
    margin-left: -2.25rem;
  }

  .md\:ml-\[-5rem\] {
    margin-left: -5rem;
  }

  .md\:ml-\[calc\(-5\.5vw-2\.25rem\)\] {
    margin-left: calc(-5.5vw - 2.25rem);
  }

  .md\:mr-\[-2\.25rem\] {
    margin-right: -2.25rem;
  }

  .md\:mr-\[-76px\] {
    margin-right: -76px;
  }

  .md\:mt-\[-100px\] {
    margin-top: -100px;
  }

  .md\:mt-\[50px\] {
    margin-top: 50px;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-\[453px\] {
    width: 453px;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:bg-\[left_30\%\] {
    background-position: 0 30%;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  .md\:py-\[100px\] {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md\:pb-\[100px\] {
    padding-bottom: 100px;
  }

  .md\:pt-\[100px\] {
    padding-top: 100px;
  }

  .md\:pt-\[60px\] {
    padding-top: 60px;
  }

  .md\:text-3xl {
    font-size: 56px;
  }

  .md\:text-5xl {
    font-size: 72px;
  }

  .md\:text-\[4\.7vw\] {
    font-size: 4.7vw;
  }

  .md\:text-\[5\.6vw\] {
    font-size: 5.6vw;
  }

  .md\:text-md {
    font-size: 22px;
  }

  .md\:text-xs {
    font-size: 20px;
  }

  .md\:leading-\[1\.1\] {
    line-height: 1.1;
  }

  .md\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .md\:leading-\[1\.5\] {
    line-height: 1.5;
  }

  .last\:md\:mb-6:last-child {
    margin-bottom: 1.5rem;
  }

  .md\:last\:ml-8:last-child {
    margin-left: 2rem;
  }

  .last\:md\:pb-6:last-child {
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-\[39px\] {
    margin-bottom: 39px;
  }

  .lg\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .lg\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  .lg\:ml-\[-2\.25rem\] {
    margin-left: -2.25rem;
  }

  .lg\:ml-\[calc\(-5\.5vw-2\.25rem\)\] {
    margin-left: calc(-5.5vw - 2.25rem);
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mr-\[-2\.25rem\] {
    margin-right: -2.25rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mt-\[-100px\] {
    margin-top: -100px;
  }

  .lg\:mt-\[100px\] {
    margin-top: 100px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:w-\[50\%\] {
    width: 50%;
  }

  .lg\:w-\[640px\] {
    width: 640px;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-7 {
    gap: 1.75rem;
  }

  .lg\:gap-\[0\.8\%\] {
    gap: .8%;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-\[47px\] {
    padding-bottom: 47px;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-\[28px\] {
    padding-left: 28px;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:text-2xl {
    font-size: 48px;
  }

  .lg\:text-2xs {
    font-size: 18px;
  }

  .lg\:text-3xl {
    font-size: 56px;
  }

  .lg\:text-3xs {
    font-size: 16px;
  }

  .lg\:text-\[clamp\(40px\,4vw\,56px\)\] {
    font-size: clamp(40px, 4vw, 56px);
  }

  .lg\:text-lg {
    font-size: 32px;
  }

  .lg\:text-md {
    font-size: 22px;
  }

  .lg\:text-sm {
    font-size: 24px;
  }

  .lg\:text-xl {
    font-size: 40px;
  }

  .lg\:text-xs {
    font-size: 20px;
  }

  .lg\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .lg\:leading-\[1\.3\] {
    line-height: 1.3;
  }

  .lg\:leading-\[1\.4\] {
    line-height: 1.4;
  }

  .lg\:leading-\[1\.5\] {
    line-height: 1.5;
  }

  .first-letter\:lg\:text-lg:first-letter {
    font-size: 32px;
  }

  .lg\:after\:ml-4:after {
    content: var(--tw-content);
    margin-left: 1rem;
  }

  .lg\:after\:mr-4:after {
    content: var(--tw-content);
    margin-right: 1rem;
  }

  .after\:lg\:block:after, .lg\:after\:block:after {
    content: var(--tw-content);
    display: block;
  }

  .lg\:after\:h-\[16px\]:after {
    content: var(--tw-content);
    height: 16px;
  }

  .lg\:after\:w-\[1px\]:after {
    content: var(--tw-content);
    width: 1px;
  }

  .lg\:after\:bg-\[\#C8C8C8\]:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(200 200 200 / var(--tw-bg-opacity));
  }

  .lg\:after\:content-\[\'\'\]:after {
    --tw-content: "";
    content: var(--tw-content);
  }

  .lg\:first\:pt-0:first-child {
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .xl\:sticky {
    position: sticky;
  }

  .xl\:bottom-\[-10px\] {
    bottom: -10px;
  }

  .xl\:top-\[28px\] {
    top: 28px;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:ml-\[-2\.25rem\] {
    margin-left: -2.25rem;
  }

  .xl\:mr-\[-2\.25rem\] {
    margin-right: -2.25rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-\[-300px\] {
    margin-top: -300px;
  }

  .xl\:mt-\[28px\] {
    margin-top: 28px;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:max-h-\[calc\(100vh-50px\)\] {
    max-height: calc(100vh - 50px);
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:gap-x-9 {
    column-gap: 2.25rem;
  }

  .xl\:text-5xl {
    font-size: 72px;
  }

  .xl\:text-sm {
    font-size: 24px;
  }

  .xl\:text-xs {
    font-size: 20px;
  }

  .xl\:after\:w-\[435px\]:after {
    content: var(--tw-content);
    width: 435px;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:text-5xl {
    font-size: 72px;
  }
}

.\[\&\.active\]\:border-b-\[1\.5px\].active {
  border-bottom-width: 1.5px;
}

.\[\&\.active\]\:border-solid.active {
  border-style: solid;
}

.\[\&\.active\]\:border-purple.active {
  --tw-border-opacity: 1;
  border-color: rgb(215 184 255 / var(--tw-border-opacity));
}

.\[\&\.menu-open\]\:fixed.menu-open {
  position: fixed;
}

.\[\&\.menu-open\]\:top-0.menu-open {
  top: 0;
}

.\[\&\.menu-open\]\:z-\[2\].menu-open {
  z-index: 2;
}

.\[\&\.menu-open\]\:h-full.menu-open {
  height: 100%;
}

.\[\&\.menu-open\]\:w-full.menu-open {
  width: 100%;
}

.\[\&\.menu-open\]\:rounded-b-none.menu-open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.\[\&\.menu-open\]\:bg-white.menu-open {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\.menu-open\]\:pb-8.menu-open {
  padding-bottom: 2rem;
}

.\[\&\.menu-open\]\:pt-7.menu-open {
  padding-top: 1.75rem;
}

@media (min-width: 640px) {
  .\[\&\.menu-open\]\:sm\:static.menu-open {
    position: static;
  }

  .\[\&\.menu-open\]\:sm\:h-auto.menu-open {
    height: auto;
  }

  .\[\&\.menu-open\]\:sm\:rounded-b-2xl.menu-open {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .\[\&\.menu-open\]\:sm\:bg-plum.menu-open {
    --tw-bg-opacity: 1;
    background-color: rgb(63 8 83 / var(--tw-bg-opacity));
  }

  .\[\&\.menu-open\]\:sm\:px-10.menu-open {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .\[\&\.menu-open\]\:sm\:py-5.menu-open {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.\[\&\.open\]\:block.open {
  display: block;
}

@media (min-width: 640px) {
  .\[\&\:nth-child\(2\)\]\:sm\:border-t-\[1px\]:nth-child(2) {
    border-top-width: 1px;
  }

  .sm\:\[\&\:nth-last-child\(-n\+2\)\]\:mb-0:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}

.\[\&\:nth-last-child\(2\)\]\:border-b-\[1px\]:nth-last-child(2) {
  border-bottom-width: 1px;
}

@media (min-width: 640px) {
  .\[\&\:nth-last-child\(2\)\]\:sm\:border-b-0:nth-last-child(2) {
    border-bottom-width: 0;
  }
}

.filter.active .\[\.filter\.active_\&\]\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.header.menu-open .\[\.header\.menu-open_\&\]\:mb-4 {
  margin-bottom: 1rem;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:mt-8 {
  margin-top: 2rem;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:block {
  display: block;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:hidden {
  display: none;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:h-full {
  height: 100%;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:w-full {
  width: 100%;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:flex-col {
  flex-direction: column;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:items-start {
  align-items: flex-start;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:font-bridge-head-ext {
  font-family: Bridge Head Ext, sans-serif;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:text-lg {
  font-size: 32px;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:leading-\[1\.3\] {
  line-height: 1.3;
}

.header.menu-open .\[\.header\.menu-open_\&\]\:text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.header.menu-open .\[\.header\.menu-open_\&\]\:hover\:text-purple:hover {
  --tw-text-opacity: 1;
  color: rgb(215 184 255 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:mb-0 {
    margin-bottom: 0;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:mt-0 {
    margin-top: 0;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:block {
    display: block;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:inline {
    display: inline;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:hidden {
    display: none;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:h-auto {
    height: auto;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:w-auto {
    width: auto;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:flex-row {
    flex-direction: row;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:items-center {
    align-items: center;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:font-akkurat {
    font-family: Akkurat LL, sans-serif;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:text-2xs {
    font-size: 18px;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:leading-\[1\.2\] {
    line-height: 1.2;
  }

  .header.menu-open .\[\.header\.menu-open_\&\]\:sm\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (min-width: 1024px) {
  .header.menu-open .\[\.header\.menu-open_\&\]\:lg\:text-xs {
    font-size: 20px;
  }
}

/*# sourceMappingURL=css.21d09b7d.css.map */
